import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <video className="logo" autoPlay loop muted>
          <source src="/logo-animated.webm" type="video/webm" />
          <source src="/logo-animated.mp4" type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>
        {/* Coming soon.. */}
      </header>
      <footer className="App-footer">
        <a
          className="cursor-pointer hover:underline"
          href="https://t.me/eDrugs_Lab"
          target="_blank"
        >
          Telegram
        </a>
      </footer>
    </div>
  );
}

export default App;
